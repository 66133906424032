/* App.css */
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.scrollable-content {
  overflow-y: scroll;
  height: calc(100vh - 90px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
